import { isWvEmail, getPartAuthCookieData } from "../shared/utils";
export function abortThenGetNewController(controller) {
    if (controller && controller.signal && !controller.signal.aborted) {
        // This is 
        controller.abort();
        controller = null;
    }
    return new AbortController();
}
export var isStaffMember = function (member) {
    return isWvEmail(member.email) || (member.teamamte === true || member.teamamte === 'true');
};
export var getSupporterId = function (currentMember) {
    return getPartAuthCookieData('supporter_number') || (currentMember === null || currentMember === void 0 ? void 0 : currentMember.externalId);
};
